.basic-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
        h1 {
            &.logo {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                img {
                    max-width: 16rem;
                    width: 100%;
                }
            }
        }
        .menu {
            > :not(:first-child) {
                margin-left: 0.5rem;
            }
            > .user {
                // :not(:first-child) {
                //     margin-left: 0.5rem;
                //     border-left: solid 1px #aaa;
                // }
                a {
                    &:not(:last-child)::after {
                        content: "";
                        border-left: solid 1px #787878;
                        margin-left: 0.4rem;
                        margin-right: 0.4rem;
                        font-size: 1rem;
                    }
                }
                // span {
                //     border-left: solid 1px #787878;
                // }
            }
        }
    }

    .main-container {
        flex-grow: 1;
        .carousel.top {
            img {
                object-fit: cover;
            }
        }
    }

    .footer {
        margin-top: 2rem;
        background-color: #e5e5e5;
        padding-top: 3rem;
        padding-bottom: 1rem;
        h5 {
            font-size: 0.9rem;
            font-weight: bold;
        }
        .social {
            font-size: 1.6rem;
            > :not(:first-child) {
                margin-left: 0.5rem;
            }
        }
        .signin-link {
            color: transparent;
        }
    }
}
