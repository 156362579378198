.signin {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    .form-wrappper {
        width: 100%;
        max-width: 380px;
        padding: 15px;
        margin: auto;
        > .card {
            > .card-header {
                h3 {
                    margin-top: 0.5rem;
                    font-size: 1.4rem;
                }
            }
            > .card-footer {
                font-size: 0.8rem;
                a {
                    text-decoration: none;
                }
            }
        }
    }
}
