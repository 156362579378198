.quotation {
    .blocking-overlay {
        background-color: #cccccc99;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 0.25rem;
        top: 0;
        left: 0;
        &.disabled {
            display: none;
        }
    }
}
