// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

$enable-negative-margins: true;
@import "~bootstrap/scss/bootstrap";
$fa-font-path: "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

html,
body,
#root,
._loading_overlay_wrapper {
    min-height: 100%;
    height: 100%;
}

._loading_overlay_wrapper {
    > ._loading_overlay_overlay {
        position: fixed;
        background-color: #ffffffb3;
        color: #b7b7b7;
        svg circle {
            stroke: #b7b7b7;
        }
    }
}

.f-box {
    display: flex;
    justify-content: flex-end;
    .f-item {
        &:first-child {
            margin-right: auto;
        }
    }
}

.options {
    padding-left: 0.8rem;
    --bs-text-opacity: 1;
    color: #6c757d;
    font-size: 0.875em;
    > li {
        list-style-type: "※";
        padding-inline-start: 0.3rem;
    }
}

.circle-level {
    > .circle {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: #b5a36f;
        border: solid 0.1rem #b5a36f;
        margin-top: 0.25rem;
        &-outline {
            background: none;
        }
    }
    :not(:first-child) {
        margin-left: 0.15rem;
    }
}

.btn {
    &-sm-lsz {
        @media screen and (min-width: 576px) {
            padding: 0.5rem 1rem;
            font-size: 1.25rem;
            border-radius: 0.3rem;
        }
    }
    &-md-nsz {
        @media screen and (min-width: 768px) {
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            border-radius: 0.25rem;
        }
    }
    &-lg-lsz {
        @media screen and (min-width: 992px) {
            padding: 0.5rem 1rem;
            font-size: 1.25rem;
            border-radius: 0.3rem;
        }
    }
}

.ps-md-default {
    @media screen and (min-width: 768px) and (max-width: 991px) {
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }
}
