.scroll-top {
    display: block;
    background: none;
    border: none;
    position: fixed;
    right: 10px;
    bottom: 10px;
    height: 80px;
    z-index: 1000;
    cursor: pointer;
    transition: opacity 0.4s;
    padding: 1rem;
    &.in {
        animation: fadeIn 0.5s;
        animation-fill-mode: forwards;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}
