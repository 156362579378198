.dropzone {
    border: #b3b3b3 1px dashed;
    border-radius: 0.3rem;
    padding: 0.7rem;
    background-color: #f1f1f1;
    > p {
        margin-bottom: 0;
    }
}
.thumb-item {
    background-color: #f1f1f1;
    padding: 12px;
    margin-bottom: 1rem;
    border-top: #aaa 1px dashed;
    border-bottom: #aaa 1px dashed;
    position: relative;
    // border-radius: 0.3rem;
    @media screen and (min-width: 576px) {
        display: flex;
        flex-wrap: nowrap;
    }

    .thumb-img {
        width: 100px;
        min-width: 100px;
        height: 100px;

        @media screen and (min-width: 576px) {
            width: 150px;
            min-width: 150px;
            height: 150px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.5rem;
        }
    }
    .thumb-right {
        padding: 12px;
        @media screen and (min-width: 576px) {
            flex-grow: 1;
        }
        .thumb-operation {
            position: absolute;
            top: 24px;
            right: 12px;
            @media screen and (min-width: 576px) {
                position: inherit;
                top: auto;
                right: auto;
            }
        }
        .thumb-info {
            background-color: #fff;
            padding: 12px;
            margin-left: -12px;
            margin-right: -12px;
            margin-bottom: -12px;
            border-radius: 0.3rem;
            @media screen and (min-width: 576px) {
                margin-left: auto;
                margin-bottom: auto;
            }
        }
    }
}
