.products {
    position: relative;
    .carousel-wrapper {
        background-color: #e9e9e9;
        .carousel {
            max-width: 92rem;
            margin-left: auto;
            margin-right: auto;
            .carousel-inner {
                .carousel-item {
                    height: 400px;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .row {
            padding-right: 0.5rem;
            > .item {
                padding-left: 0.5rem;
                padding-right: 0;
            }
        }
    }

    .card {
        .price {
            font-size: 0.9rem;
            font-weight: 600;
        }
        .edition {
            color: #ac9b6f;
            margin-bottom: 0.2rem;
            font-size: 0.8rem;
        }
        .card-title {
            font-size: 0.9rem;
            margin-bottom: 0.1rem;
        }
        .special-price {
            font-size: 0.9rem;
            font-weight: 600;
            color: #b81836;
        }
        .estimate-price {
            font-size: 0.9rem;
        }
        .supplementary-information {
            font-size: 0.8rem;
            color: #6c757d;
            margin-bottom: 0.1rem;
        }
    }
    .label {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #afafaf;
        color: #fff;
        padding: 0.15rem 0.5rem;
        border-radius: 0 0.25rem 0 0;
        font-size: 0.8rem;
        span {
            margin-left: 0.2rem;
            font-size: 0.7rem;
        }
        &.sale {
            background-color: #b81836;
        }
        &.in-stock-soon {
            background-color: #42823f;
        }
        &.only-few-left {
            background-color: #f2b90f;
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000040;
        border-radius: 0.25rem;
    }
}

.badge {
    &.bg-gray {
        background-color: #afafaf;
    }
    &.bg-yellow {
        background-color: #f2b90f;
    }
    &.bg-red {
        background-color: #b81836;
    }
}
