.product {
    .pict-box {
        .thumbnail-area {
            overflow: scroll;
            min-width: 4.6rem;
            -ms-overflow-style: none; /* IE, Edge 対応 */
            scrollbar-width: none; /* Firefox 対応 */
            &::-webkit-scrollbar {
                /* Chrome, Safari 対応 */
                display: none;
            }
            // sm
            @media (min-width: 576px) {
                height: 23.5rem;
            }
            .thumbnail-box {
                position: relative;
                &:not(:first-child) {
                    margin-left: 0.5rem;
                }
                // sm
                @media (min-width: 576px) {
                    &:not(:first-child) {
                        margin-top: 0.5rem;
                        margin-left: 0;
                    }
                }
                > img {
                    max-width: 4.6rem !important;
                    height: 4.6rem !important;
                    min-width: 4.6rem;
                    min-height: 4.6rem;
                    object-fit: cover;
                }
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: "";
                    width: 100%;
                    height: 100%;
                    background-color: #00000030;
                    border-radius: 0.25rem;
                }
                &.active::after {
                    background: none;
                }
            }
        }
        .main-img-area {
            width: 100%;
            .main-img-frame {
                position: relative;
                overflow: hidden;
                .main-img {
                    width: 100% !important;
                    height: auto !important;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
    dl {
        &.spec {
            > dt {
                float: left;
                &::after {
                    content: ":";
                }
            }

            > dd {
                margin-left: 6rem;
            }
        }
    }
    .edition {
        color: #ac9b6f;
        font-size: 0.9rem;
    }
    .name {
        font-size: 1.6rem;
        margin-bottom: 0;
    }
    .price {
        margin-top: 1rem;
    }
    .special-price {
        font-size: 1.2rem;
        color: #b81836;
    }
    .catch-phrase {
        margin-top: 0.5rem;
        color: #b81836;
    }
    .stock-quantity {
        margin-top: 0.5rem;
        font-size: 0.9rem;
    }
    .estimate-link {
        text-decoration: none;
        --bs-text-opacity: 1;
        color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
        font-size: 1.1rem;
        font-weight: bold;
    }
    .description {
        margin-top: 2rem;
    }
    .badge {
        margin-bottom: 0.3rem;
        &.bg-sale {
            background-color: #b81836 !important;
        }
    }
}
