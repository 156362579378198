.stepflow {
    font-size: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 1em;
    padding: 0;
    > li {
        position: relative;
        display: block;
        width: auto;
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        text-overflow: ellipsis;
        color: #b0bec5;

        -ms-flex: 1;
        -moz-flex: 1;
        -webkit-box-flex: 1;
        flex: 1;
        .bubble {
            display: block;
            width: 20px;
            height: 20px;
            margin: 0 auto 0.5em;
            border-radius: 1000px;
            background-color: #b0bec5;
            &::after,
            &::before {
                position: absolute;
                top: 9px;
                display: block;
                width: 100%;
                height: 3px;
                content: "";
                background-color: #b0bec5;
            }
        }
        &.completed,
        &.completed .bubble {
            color: #00acc1;
        }
        &.completed .bubble,
        &.completed .bubble::after,
        &.completed .bubble::before {
            background-color: #4dd0e1;
        }
        &.completed > .bubble + span::before {
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            content: "\f058";
            margin-right: 3px;
        }
        &:last-child .bubble::after,
        &:last-child .bubble::before {
            width: 0%;
            margin-right: 50%;
        }
        &.active,
        &.active .bubble {
            font-weight: bold;
            color: #f57c00;
        }
        &.active .bubble,
        &.active .bubble::after,
        &.active .bubble::before {
            background-color: #fb8c00;
        }
    }
}
